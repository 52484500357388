import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 99999,
        width: '100%',
        height: 'auto',
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[4],
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        "& div": {
            margin: theme.spacing(1),
        },
    }
}));

