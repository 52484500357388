import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { SaurusTheme } from './views/theme/saurus-theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { RouterApp } from './routers';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import 'core-js/features/global-this';
 
// import replaceAllInserter from 'string.prototype.replaceall'

import './assets/css/join.css';

import './assets/css/reset.css';
import { VariaveisAmbiente } from './config';
import { useThemeQueries } from './views';
import { SaurusSafraTheme } from './views/theme/saurus-safra-theme';
import { EnvironmentConfig } from './config/components/enviroment.config';

const replaceAllInserter = require('string.prototype.replaceall');

//strictMode para DEV APENAS
export const StrictMode: FC = ({ children }) => {
  return (
    <>
      {VariaveisAmbiente.isDev && (
        <React.StrictMode>{children}</React.StrictMode>
      )}
      {!VariaveisAmbiente.isDev && <>{children}</>}
    </>
  );
};

replaceAllInserter.shim();

(window as any).process = {};


const HotToast = () => {
  const { toasts } = useToasterStore();
  const { isMobile } = useThemeQueries();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.remove(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  return (
    <Toaster
      toastOptions={{
        style: {
          width: '100%',
        },
        duration: 3000,
      }}
      containerStyle={{
        top: 60,
        left: 16,
        bottom: isMobile ? 65 : 60,
        right: 16,
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            return (
              <div
                onClick={() => toast.dismiss(t.id)}
                style={{ display: 'flex', height: '100%', width: '100%' }}
              >
                {icon}
                {message}
              </div>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

if (!Object.entries) {
  Object.entries = function (obj: any) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

const renderReactDom = () => {
  ReactDOM.render(
    <>
      <StrictMode>
      <EnvironmentConfig>
        <ThemeProvider
          theme={
            VariaveisAmbiente.label === 'SafraPDV'
              ? SaurusSafraTheme
              : SaurusTheme
          }
        >
          <CssBaseline>
            <HotToast />
            <ConfirmProvider
              defaultOptions={{
                title: 'Confirma a Operação?',
                cancellationButtonProps: {
                  variant: 'text',
                  color: 'secondary',
                },
                confirmationButtonProps: {
                  variant: 'text',
                },
                confirmationText: 'Sim',
                cancellationText: 'Não',
              }}
            >
              <RouterApp />
            </ConfirmProvider>
          </CssBaseline>
        </ThemeProvider>
        </EnvironmentConfig>
      </StrictMode>
    </>,
    document.getElementById('root'),
  );
};

if ((window as any).cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}
