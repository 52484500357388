import { useCallback } from "react";
import { useDevice } from "./device";

export const useImpressao = () => {
  const { printNative } = useDevice()

  const imprimir = useCallback(async (qtdeColunas: number, textoImpressao: string, caminho: string, modelo: string, quantidadeVias: number) => {
    return await printNative(
      textoImpressao,
      Number(qtdeColunas || '0'),
      caminho ?? '',
      modelo ?? '',
      quantidadeVias ?? 0
    );

  }, [printNative])
  return ({ imprimir })
}