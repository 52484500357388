import { isEmpty } from "lodash";
import React, { useState } from "react";
import { VariaveisAmbiente } from "../config";
import { EnumDeviceType } from "../model/enums/enum-device-type";

export const useDevice = () => {
  const [carregandoInterno, setCarregandoInternoState] = useState(false);

  const setCarregandoInterno = (valor: boolean) => {
    setCarregandoInternoState((prev) => {
      if (prev !== valor) return valor;
      return prev
    })
  }
  const carregando = carregandoInterno;

  const DeviceKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).NavigatorWebKit;
      case EnumDeviceType.CORDOVA:
        return (window as any).CordovaWebKit;
    }
  }, []);

  const PrintNativeKit = React.useCallback((): any => {
    switch (VariaveisAmbiente.paymentDevice) {
      case EnumDeviceType.NAVIGATOR:
        return (window as any).launchPrintNativeNavigator;
      case EnumDeviceType.CORDOVA:
      case EnumDeviceType.CORDOVA_POS:
        return (window as any).launchPrintNativeCordova;
    }
  }, []);

  const integrationStatus = React.useCallback(async (): Promise<boolean> => {
    return !isEmpty(DeviceKit());
  }, [DeviceKit]);

  const printNative = React.useCallback(
    async (
      textoImpressao: string,
      qtdeColunas: number,
      caminho: string,
      modelo: string,
      vias: number,
    ): Promise<void> => {
      setCarregandoInterno(true);
      return new Promise<void>(async (resolve, reject) => {
        if (!(await integrationStatus())) {
          reject(new Error('SDK não Inicializado'));
        }

        const print = PrintNativeKit();
        try {
          print(
            textoImpressao,
            qtdeColunas,
            caminho,
            modelo,
            vias,
            function (result: any, error: any) {
              setCarregandoInterno(false);
              if (error) {
                reject(error);
              }
              if (result !== 'success') {
                reject(
                  new Error('Erro ao realizar a Impressão. Detalhe: ' + result),
                );
              }
              resolve();
            },
          );
        } catch (e: any) {
          setCarregandoInterno(false);
          reject(e);
        }
      });
    },
    [PrintNativeKit, integrationStatus],
  );


  return {
    printNative,
    carregando
  }

}