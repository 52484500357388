import { Divider, Grid, GridSize, Typography } from "@material-ui/core";
import { isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetProcessoProdutoSetor } from "../../../../data/api/gestao/processo-produto-setor/get-processo-produto-setor";
import { ProcessoProdutoSetorModel } from "../../../../model/api/processo-produto-setor/processo-produto-setor-model";
import { ProcessoSetorModel } from "../../../../model/api/processo-setor/processo-setor-model";
import { KdsStorageKeys, useKdsStorage, useToastSaurus } from "../../../../services/app";
import { useSessaoAtual } from "../../../../services/app/providers/sessao-atual-provider";
import { CircularLoading } from "../../../components";
import { PublicPageHeader } from "../../../components/headers/header-public-page/header-public-page";
import { Navbar } from "../../../components/nav";
import { MonitoramentoSetoresProcessosList } from "./components/monitoramento-setores-processos-list/monitoramento-setores-processos-list";
import { useStyles } from "./monitoramento-setores-styles";
import { EnumStatusPedido } from "../../../../model/enums/enum-status-pedido";
import { useThemeQueries } from "../../../theme";
import { PedidoModel } from "../../../../model/api/pedidos/pedido-model";
import { useGetPedido } from "../../../../data/api/gestao/pedido/get-pedido";
import { DialogQRCodeEntrega } from "../../../components/dialog/dialog-qr-code-entrega/dialog-qr-code-entrega";
import { AdicionarRemoverProcessos } from "../../../../model/app/forms/adicionar-remover-processos/adicionar-remover-processos";
import { useImpressao } from "../../../../hooks/impressao";
import { VariaveisAmbiente } from "../../../../config";
import { EnumDeviceType } from "../../../../model/enums/enum-device-type";
import { ProcessoProdutoSetorModelNovo } from "../../../../model/api/processo-produto-setor/put-processo-produto-setor";
import { usePutAlterarProcesso } from "../../../../data/api/gestao/processo-produto-setor/put-processo-produto-setor";
import { ConfigLayoutFormModel } from "../../../../model/app/forms/master/config-layout-model";
import { AppEventEnum } from "../../../../model/enums/enum-app-event";
import { useEventTools } from "../../../../services/app/use-cases/events/event-tools";
import { EnumTipoLayout } from "../../../../model/enums/enum-config-tipo-layout";

const MonitoramentoSetoresPage = () => {

   //AUX
   const history = useHistory()
   const { showToast } = useToastSaurus()
   const classes = useStyles();
   const { theme } = useThemeQueries();
   const { imprimir } = useImpressao()

   //PROVIDERS
   const { getRegistro } = useKdsStorage()
   const { getEmpresaSelecionada } = useSessaoAtual()
   const setoresStorage = getRegistro(KdsStorageKeys.ProcessosSetores, false) as ProcessoSetorModel[]

   //CHAMADAS API
   const { getProcessoProdutoSetor, carregando: carregandoProduto } = useGetProcessoProdutoSetor()
   const { getPedido, carregando: carregandoPedido } = useGetPedido();
   const { putAlterarProcesso, carregando: carregandoPutProcesso } = usePutAlterarProcesso()

   const carregando = carregandoPedido || carregandoProduto || carregandoPutProcesso;

   //STATES E REFS
   const carregouRef = useRef<boolean>(false)
   const [pedidos, setPedidos] = useState<PedidoModel[]>([]);
   const [produtos, setProdutos] = useState<Array<ProcessoProdutoSetorModel>>([])
   const [abrirQrCode, setAbrirQrCode] = useState<boolean>(false);
   const [tamanhoDoSetor, setTamanhoSetor] = useState<GridSize>(12)
   const [tamanhoDoProcessos, setTamanhoProcessos] = useState<GridSize>(12)
   const [processosSelecionados, setProcessosSelecionados] = useState<AdicionarRemoverProcessos[]>([]);
   const { addHandler, removeHandler } = useEventTools();


   const produtosDistintosRef = useRef<ProcessoProdutoSetorModel[]>([]);
   const produtosNaoImpressosRef = useRef<ProcessoProdutoSetorModel[]>([]);
   const interval = useRef<NodeJS.Timer>();

   const handleClose = useCallback(() => {
      setAbrirQrCode(false);
      setProcessosSelecionados([]);
   }, [])

   const setores: ProcessoSetorModel[] = useMemo(() => isEmpty(setoresStorage) ? [] : setoresStorage, [setoresStorage])

   const getPedidosWrapper = useCallback(async () => {
      try {
         const res = await getPedido(getEmpresaSelecionada()?.Id!);

         if (res.erro) throw res.erro;

         return setPedidos(res.resultado?.data.list)
      } catch (e: any) {

      }
   }, [getEmpresaSelecionada, getPedido])

   useEffect(() => {
      if (isEmpty(setores)) {
         history.push('/selecionar-setor')
         showToast('info', 'Selecione os setores para visualizar a página.')
      }
   }, [history, setores, showToast])

   const getProcessoProdutoSetorWrapper = useCallback(async () => {
      try {
         // setores.map(item => item.processos).reduce((arr, obj) => arr.concat(obj), []).map(x => x.id).toString().replaceAll(',', ';')
         const query = setores.map(item => item.processos.filter(item => item.processoProducao < 9000).map(item => `${item.id};`).toString().replaceAll(',', ''))
         const saloes = setores.filter(x => !isEmpty(x.salao)).map(item => (`${item.salao.id};`).toString().replaceAll(',', ''));
         const queryString = ('&processosIds=' + query.map(item => item).toString().replaceAll(',', '')) +
            (!isEmpty(saloes) ? ('&salaoId=' + saloes.map(item => item).toString().replaceAll(',', '')) : '');

         const res = await getProcessoProdutoSetor(getEmpresaSelecionada()?.Id ?? '', queryString + '&pageSize=10000')

         if (res.erro) throw res.erro

         const produtos = res.resultado?.data.list as ProcessoProdutoSetorModel[]
         setProdutos(produtos);

         produtosDistintosRef.current = produtos.reduce<ProcessoProdutoSetorModel[]>((prev, curr) => {
            if (prev.some((y) => y.pedidoId === curr.pedidoId)) return [...prev]
            return [...prev, curr];
         }, [])
         produtosNaoImpressosRef.current = produtosDistintosRef.current.filter(x => !x.produtoImpresso)

         return
      } catch (e: any) {
         showToast('error', e.message)
      }
   }, [getEmpresaSelecionada, getProcessoProdutoSetor, setores, showToast])

   const putProcessosWrapper = useCallback(async (produtos: ProcessoProdutoSetorModel[], produtoAtual: ProcessoProdutoSetorModel) => {
      try {
         const ids = produtos.map(x => x.id)
         const novoModelo = new ProcessoProdutoSetorModelNovo(ids, true)
         let ret: any = ''
         ret = await putAlterarProcesso(novoModelo, getEmpresaSelecionada()?.Id || '', produtoAtual.processoProducaoAtual)
         if (ret.erro) {
            throw ret.erro
         }
      } catch (e: any) { }
   }
      , [getEmpresaSelecionada, putAlterarProcesso])

   const printType = useCallback((): 'HTML' | 'NATIVE' => {
      switch (VariaveisAmbiente.paymentDevice) {
         case EnumDeviceType.NAVIGATOR:
            return 'HTML';
         case EnumDeviceType.CORDOVA:
            return 'NATIVE';
         default: return 'HTML'
      }
   }, []);
   const imprimirNativo = useCallback(async () => {
      try {
         if (produtosNaoImpressosRef.current.length > 0
            && printType() === 'NATIVE'
         ) {
            produtosNaoImpressosRef.current.forEach(async (produto: ProcessoProdutoSetorModel) => {
               if (!produto.produtoImpresso && !isEmpty(produto.impressoes)) {
                  for (let i = 0; i <= produto.impressoes.length; i++) {
                     await putProcessosWrapper(produtosDistintosRef.current, produto)
                     await imprimir(
                        produto.impressoes[i].equipamento.colunas,
                        produto.impressoes[i].impressao,
                        `${produto.impressoes[i].equipamento.caminho}|${produto.impressoes[i].equipamento.usuario}|${produto.impressoes[i].equipamento.senha}`,
                        produto.impressoes[i].equipamento.modelo,
                        produto.impressoes[i].equipamento.quantidadeVias
                     )

                  }
               }
            })
         }
         return
      } catch (e: any) { showToast('error', e.message) }

   }, [imprimir, printType, putProcessosWrapper, showToast])


   const verificarPedidos = useCallback(async () => {
      const asyncInterval = async () => {
         await getProcessoProdutoSetorWrapper()
         await getPedidosWrapper();
         if (produtosNaoImpressosRef.current.length > 0 && printType() === 'NATIVE') {
            await imprimirNativo()
         }
      }
      asyncInterval();
      clearInterval(interval.current);
      interval.current = setInterval(() => {
         asyncInterval();
      }, 20000);
   }, [getPedidosWrapper, getProcessoProdutoSetorWrapper, imprimirNativo, printType])

   const retornaTamanhoComponente = useCallback((tipo: EnumTipoLayout, atualizaValor: (gridSize: GridSize) => void) => {
      const configLayout = getRegistro(KdsStorageKeys.ConfiguracoesLayout, false) as ConfigLayoutFormModel
      if (isEmpty(configLayout)) {
         return
      }

      const qtdDeComponentesConfigurados = () => {

         if (tipo === EnumTipoLayout.SETORES) {

            return +configLayout?.qtdSetores
         }
         if (tipo === EnumTipoLayout.PROCESSOS) {
            return +configLayout?.qtdProcessos
         }
      }
      let qtdAtual = qtdDeComponentesConfigurados()

      if ((qtdAtual ?? 12) > 12) {
         atualizaValor(1 as GridSize)
      }
      const tamanhoCalculado = Math.round(12 / (qtdAtual ?? 12))

      atualizaValor(tamanhoCalculado as GridSize)
   }, [getRegistro])

   useEffect(() => {
      if (!carregouRef.current) {
         verificarPedidos();
      }

      carregouRef.current = true

      window.addEventListener('popstate', () => {
         clearInterval(interval.current);
      })
   }, [getPedidosWrapper, getProcessoProdutoSetorWrapper, imprimirNativo, produtosNaoImpressosRef.current.length, verificarPedidos])

   const getLayoutSize = () => {
      retornaTamanhoComponente(EnumTipoLayout.SETORES, setTamanhoSetor)
      retornaTamanhoComponente(EnumTipoLayout.PROCESSOS, setTamanhoProcessos)
   }

   useEffect(() => {
      getLayoutSize()
      addHandler(AppEventEnum.LayoutAlterado, getLayoutSize)
      return () => {
         removeHandler(AppEventEnum.LayoutAlterado, getLayoutSize)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <>
         <PublicPageHeader topOpacity="0.8" />
         {carregando && (
            <div className={classes.loading}>
               <CircularLoading tipo="FULLSIZED" />
            </div>
         )}
         <div className={classes.root}>
            <Navbar titulo="Monitoramento" />
            <Grid container>
               {setores
                  .map((setor) => {
                     const produtosSetor = produtos.filter(produto => {
                        const prods = setores.filter(x => x.setor.id === produto.setorId)
                        return (produto?.setorId === setor?.setor.id) &&
                           produto.status.codigo !== EnumStatusPedido.Cancelado &&
                           (setor.salao ? isEqual(produto.salaoId, setor.salao?.id) : prods)
                     }
                     )
                     // Definir tamanho do grid de acordo com a quantidade de setores setados no localStorage 
                     const diffConcluidosCancelados = setor?.processos.filter(item => (item.processoProducao < 9000))
                     return (
                        <Grid item xs={tamanhoDoSetor} className={classes.gridSetor} >
                           <Grid item xs={12} className={classes.setorTitle} >
                              {/* <img src={setor.setor?.uriImage} alt="" /> */}
                              <Typography className={classes.title} >
                                 {setor?.setor.descricao}
                              </Typography>
                              <Typography
                                 variant="body2"
                                 style={{
                                    paddingLeft: '8px',
                                 }}
                              >
                                 {!isEmpty(setor?.salao?.descricao) ? `(${setor?.salao?.descricao})` : '(Sem Salão Cadastrado)'}
                              </Typography>
                           </Grid>

                           <Grid item xs={12}>
                              <Divider variant="fullWidth" style={{ background: theme.palette.grey[400] }} />
                           </Grid>

                           <Grid item xs={12} >
                              <Grid container spacing={2} style={{ padding: '16px 0px' }}>
                                 <MonitoramentoSetoresProcessosList
                                    pedidos={pedidos}
                                    getProcessoProdutoSetorWrapper={getProcessoProdutoSetorWrapper}
                                    processos={diffConcluidosCancelados}
                                    produtosSetor={produtosSetor}
                                    setAbrirQrCode={setAbrirQrCode}
                                    processosSelecionados={processosSelecionados}
                                    setProcessosSelecionados={setProcessosSelecionados}
                                    setores={setores}
                                    tamanhoDoComponenteDeProcessos={tamanhoDoProcessos}
                                    tamanhoDoSetor={tamanhoDoSetor}
                                 />
                              </Grid>
                           </Grid>

                        </Grid>
                     )
                  })}
            </Grid>
         </div >

         <DialogQRCodeEntrega
            open={abrirQrCode}
            carregando={carregando}
            model={processosSelecionados}
            handleClose={handleClose}
            processos={produtos}
         />
      </>
   )
};

export default MonitoramentoSetoresPage